import { useEffect, useState } from 'react';

import { useSearchParamsContext } from '../contexts/SearchParamsContext';

/**
 * A useState() hook wrapper that also persists the value into the query string.
 */
export const usePersistedState = (key: string) => {
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const [value, setValue] = useState<string | undefined>(searchParams.get(key) ?? undefined);

    // When the local state changes, update the search params
    useEffect(() => {
        if (value === undefined) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
        setSearchParams(searchParams);
    }, [value]);

    // When the search params change, update the local state
    useEffect(() => {
        const param = searchParams.get(key);
        if (param !== value) {
            setValue(param ?? undefined);
        }
    }, [searchParams.get(key)]);

    return [value, setValue] as const;
};
