import { createSelector } from '@reduxjs/toolkit';

import { ROOT_TREE_ID } from 'src/strings';

import type { RootState } from '../store';
import {
    ObjectTreeState,
    SelectionListState,
    VisualizationState,
    ExpandedTreeState,
    TreeStructure,
} from './visualizationSlice.types';

export const initialSelectionState: SelectionListState = {
    selection: [],
    shiftSelection: [],
};

const initialObjectTreeState: ObjectTreeState = {
    treeState: {},
    expanded: {},
};

export const initialState: VisualizationState = {
    projectTree: {
        selected: initialSelectionState,
    },
    scenePanel: {
        selected: initialSelectionState,
    },
    loadedObjects: {},
    overlays: { scalebar: true, orientation: true },
    objectTree: initialObjectTreeState,
};

const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjectsMap = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string) =>
    createSelector(
        visualizationState,
        (visualizationStateRoot): TreeStructure | undefined =>
            visualizationStateRoot.loadedObjects[objectId],
    );

export const selectionListScenePanel = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.selection,
);
export const shiftSelectionScenePanel = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.shiftSelection,
);

export const lastSelectedScenePanel = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.lastSelected ?? '',
);

export const isSelectedInScenePanel = (objectId: string) =>
    createSelector(selectionListScenePanel, (selectionList) => selectionList.includes(objectId));

export const selectionListProjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.selection,
);
export const shiftSelectionProjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.shiftSelection,
);

export const lastSelectedProjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.lastSelected ?? '',
);

export const isSelectedInProjectTree = (objectId: string) =>
    createSelector(selectionListProjectTree, (selectionList) => selectionList.includes(objectId));

export const getObjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.objectTree,
);
export const getObjectTreeExpanded = createSelector(
    getObjectTree,
    (objectTree): ExpandedTreeState => objectTree.expanded,
);
export const getObjectTreeExpandedById = (treeId: string) =>
    createSelector(getObjectTreeExpanded, (expanded) => expanded[treeId] ?? false);

export const getTreeItemById = (treeId: string) =>
    createSelector(
        getObjectTree,
        (objectTree): TreeStructure | undefined => objectTree.treeState[treeId],
    );

export const getTreeStateIds = createSelector(getObjectTree, (objectTree) => {
    const getObjectTreeIdsByTreeId = (treeId: string): string[] => {
        const treeItem = objectTree.treeState[treeId];
        if (!treeItem) return [treeId];

        const childrenIds = treeItem.children?.flatMap(getObjectTreeIdsByTreeId) ?? [];
        return treeItem.children ? childrenIds : [treeId, ...childrenIds];
    };

    return getObjectTreeIdsByTreeId(ROOT_TREE_ID);
});
