import { AddMapLayers, MapLayerObject } from '@local/map-viewer/dist/layers/AddMapLayers';
import { MapLayer } from '@local/map-viewer/dist/types';
import { ElementsIcon, CheckIcon } from '@local/web-design-system/dist/icons';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useLeafletContext } from '@react-leaflet/core';
import classnames from 'classnames';
import { Layer } from 'leaflet';
import { useState } from 'react';

import { VIEW_LAYERS } from 'src/strings';

import { useStyles } from './Control.styles';
import { MapControlTooltip } from './MapControlsTooltip';

interface LayerGroupProps {
    defaultLayer?: MapLayer;
}

// Note: When this component is unmounted, it will loose the active layer state.
// This means in dev on a hot reload, this component will lose context of what map layers have been added.
// For this component to work, you will need to force refresh the browser, so the map layers are re-added.
export function MapLayerControl({ defaultLayer = MapLayer.SatelliteMaps }: LayerGroupProps) {
    const { classes } = useStyles();
    const parentContext = useLeafletContext();
    const container = parentContext.layerContainer || parentContext.map;
    const [activeLayerID, setActiveLayerID] = useState<MapLayer | null>(null);
    const [controlledLayers, setControlledLayers] = useState<MapLayerObject>({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const setActiveLayer = (layerID: MapLayer) => {
        if (activeLayerID === layerID) {
            return;
        }

        if (activeLayerID) {
            container.removeLayer(controlledLayers[activeLayerID] as Layer);
        }

        container.addLayer(controlledLayers[layerID] as Layer);
        setActiveLayerID(layerID);
    };

    const onAddLayerControl = (layers: MapLayerObject) => {
        setControlledLayers(layers);
        if (activeLayerID === null) {
            const newLayerID =
                defaultLayer in layers ? defaultLayer : (Object.keys(layers)[0] as MapLayer);
            container.addLayer(layers[newLayerID] as Layer);
            setActiveLayerID(newLayerID);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <>
            <MapControlTooltip placement="left" title={VIEW_LAYERS}>
                <IconButton
                    onClick={handleClick}
                    className={classnames(classes.controlButton, {
                        [classes.layerButtonActive]: Boolean(anchorEl),
                    })}
                >
                    <ElementsIcon className={classes.layersIcon} />
                </IconButton>
            </MapControlTooltip>
            <Popper
                anchorEl={anchorEl}
                placement="left-start"
                open={Boolean(anchorEl)}
                className={classes.popper}
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grid container direction="column" className={classes.layerControlBase}>
                        {Object.keys(controlledLayers).map((layerID) => (
                            <Grid
                                item
                                container
                                key={layerID}
                                wrap="nowrap"
                                direction="row"
                                className={classes.layerRow}
                                onClick={() => setActiveLayer(layerID as MapLayer)}
                            >
                                <Checkbox
                                    size="small"
                                    icon={<div />}
                                    checked={activeLayerID === layerID}
                                    checkedIcon={<CheckIcon className={classes.checkboxIcon} />}
                                    classes={{
                                        root: classes.checkboxRoot,
                                        checked: classes.checkboxChecked,
                                    }}
                                />

                                <Typography className={classes.layerRowText}>{layerID}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </ClickAwayListener>
            </Popper>
            <AddMapLayers onAddLayerControl={onAddLayerControl} />
        </>
    );
}
