import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    loadedObjectsMap,
    getTreeItemById,
    selectionListProjectTree,
    getObjectTree,
} from 'src/store/visualization/selectors';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId: string) {
    const dispatch = useAppDispatch();
    const selectedObjectIds = useAppSelector(selectionListProjectTree);

    const treeItem = useAppSelector(getTreeItemById(listItemId));
    function loadObject() {
        if (!treeItem?.schema) return;
        if (isObjectLoaded(listItemId)) return;
        setIsError(false);
        setIsLoading(true);
        dispatch(addToLoadedObjects(treeItem));
    }
    function reloadObject() {
        if (!treeItem?.schema) return;
        setIsError(false);
        setIsLoading(true);

        // First, we remove the object from the loaded objects so that it can be reloaded
        dispatch(removeFromLoadedObjects(listItemId));

        // Then, we dispose the entity to remove it from the plot
        const viewId = getViewIdFromObjectId(listItemId, treeItem.schema);
        disposeEntity(viewId);

        // Finally, we add the object back to the loaded objects
        // It needs to be inside a setTimeout because Redux won't unmount the ListItem
        // component if we dispatch this action synchronously
        setTimeout(() => dispatch(addToLoadedObjects(treeItem)));
    }

    const { treeState } = useAppSelector(getObjectTree);
    function loadSelectedObjects() {
        selectedObjectIds.forEach((treeId) => {
            const selectedTreeItem = treeState[treeId];
            if (selectedTreeItem) {
                dispatch(addToLoadedObjects(selectedTreeItem));
            }
        });
    }

    const loadedObjects = useAppSelector(loadedObjectsMap);
    function isObjectLoaded(objectId: string) {
        return objectId in loadedObjects;
    }

    const { useXyzListener, addViewStatusListener, disposeEntity } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        const objectViewId = views.find((view) => view.startsWith(listItemId));
        if (!objectViewId) {
            setIsLoading(false);
            return () => {};
        }

        const removeViewStatusListener = addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                setIsLoading(false);
                setIsError(false);
                removeViewStatusListener();
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                setIsError(true);
            },
            onPending: () => {
                setIsLoading(true);
                setIsError(false);
            },
        });
        return () => {
            removeViewStatusListener();
        };
    }, [views]);

    return {
        loadObject,
        loadSelectedObjects,
        reloadObject,
        isError,
        isLoading,
        isObjectLoaded,
    };
}
