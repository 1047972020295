import packageJson from '../package.json';

const { version } = packageJson;

/**
 * Errors
 */

export const NO_INTERNET_CONNECTION = 'There is no internet connection';
export const ERROR_BOUNDARY_MESSAGE = 'Something has gone wrong.';
export const ERROR_BOUNDARY_DETAILS =
    'Try reloading the page, checking the connection, proxy or firewall settings. Otherwise, contact the system admin.';

/**
 * Common
 */

export const CLOSE = 'Close';
export const CANCEL = 'Cancel';
export const COPY = 'Copy ID';
export const BACK = 'Back';
export const DELETE = 'Delete';
export const DOWNLOAD = 'Download';
export const BACK_TO_WORKSPACES = 'Back to Workspaces';
export const BACK_TO_USERS = 'Back to Users';
export const WORKSPACES_AND_PERMISSIONS = 'Workspaces and Permissions';
export const COPIED_TO_CLIPBOARD = 'Copied to clipboard!';
export const DRAG_AND_DROP = 'Drag and drop files to upload';

/**
 * Services
 */

export const BLOCKMODEL_SERVICE = 'blockmodel';
export const EVO_SERVICE = 'evo';

/**
 * Landing Page
 */

// Hero
export const LEARN_MORE_LINK = 'Learn more &nbsp;›';
export const OVERVIEW = 'Overview';
export const CONTACT_US = 'Contact us';

// CardList
export const CARD_LIST_TITLE = 'Cloud Applications, Powered by Seequent Evo';
export const CARD_LIST_DESCRIPTION =
    'Explore a host of Cloud Applications available now and coming soon. Learn more, request a trial or access now, simply login to discover your options.';

// Card
export const LOGIN = 'Login';
export const EAP_LOGIN = 'EAP Login';
export const LEARN_MORE_BUTTON = 'Learn more';
export const REQUEST_A_TRIAL = 'Request a trial';
export const REQUEST_A_DEMO = 'Request a demo';
export const REGISTER_INTEREST = 'Register Interest';

// Release Status
export const COMING_SOON = 'COMING SOON';

// LearnMore
export const REQUEST_TRIAL = 'Request trial';

// Header
export const LOGIN_TO_WORKSPACES = 'Login';

// Footer
export const FOOTER_SLOGAN_1 = 'Powered by';
export const FOOTER_SLOGAN_2 = 'Seequent Evo';
export const FOOTER_DEVELOPERS = 'Developers';
export const FOOTER_CONTACT_US = 'Contact us';
export const FOOTER_SEEQUENT_URL = 'Seequent.com';
export const FOOTER_SEEQUENT_COPYWRIGHT =
    '©2022 Seequent, The Bentley Subsurface Company. All rights reserved';

// enquire evo
export const EVO_ENQUIRE = 'Enquire about Seequent Evo';
export const EVO_ENQUIRE_SUBTEXT =
    "Whether you're a customer wanting to connect your solutions, or a partner wanting to build a unique integration. ";
export const EVO_ENQUIRE_SUBTEXT2 = ' to get involved.';
export const EVO_ENQUIRE_LINK = 'https://www.seequent.com/company/seequent-evo/enquire/';

/**
 * Authenticated View
 */

// Scaffolding
export const TAGLINE = '© Seequent, The Bentley Subsurface Company';
export const VERSION_LABEL = `Version ${version}`;
export const VERSION = `${version}`;
export const SCAFFOLDING_TITLE = 'It all starts with the data';

// Login related errors
export const LOGIN_FAILED = 'Login failed';
export const LOG_IN_AGAIN = 'Please log in again, or contact Seequent if this continues';

// workspace not found
export const WORKSPACE_NOT_FOUND_TITLE = 'Workspace not found';
export const WORKSPACE_NOT_FOUND_MESSAGE =
    'Workspace may have been removed, or you do not have access.';

// 404 page
export const NOT_FOUND_TITLE = 'Page not found';
export const USER_NOT_FOUND_TITLE = 'User not found';
export const NOT_FOUND_MESSAGE = 'Double check the URL or try again later';

// workspace page
export const NETWORK_ERROR_TITLE = 'Something went wrong';
export const NETWORK_ERROR_DESCR = 'If this problem persists, please contact Support';
export const WORKSPACE_CONTENT_NO_CONTENT = 'No Geoscience Objects';
export const WORKSPACE_CONTENT_NO_CONTENT_DESC =
    'Upload objects to share them with everyone in your team';

// Users page
export const ADMINISTRATORS_TITLE = 'Administrators';
export const ADD_USER_TOOLTIP =
    'Changes made in the Bentley portal may take some time to appear in this list.';
export const RESTRICTED_NO_PERMISSION_USERS = "You don't have permission to edit users.";

// User page
export const EDIT_USER_TITLE = 'Edit User';
export const USER_DETAILS = 'User Details';
export const USER_SAVE = 'Save';
export const USER_DELETE_FAIL = 'Failed to remove user';
export const USER_DELETE_SUCCESS = 'Successfully removed user';
export const USER_WORKSPACE_DETAILS = 'Workspace Permissions';
export const USER_ADMIN = 'Admin';
export const USER_BLOCKSYNC = 'Blocksync';
export const USER_UPDATE_SUCCESS = 'Successfully updated user roles';
export const USER_UPDATE_FAIL = 'Failed to update user roles';
export const ADMIN_CANNOT_SELF_DELETE =
    'Admins cannot remove themselves, please ask another admin to do so';
export const ADMIN_TOOLTIP =
    'Evo admins can manage Evo app licenses and configure Workspaces at an organisational level.';
export const USER_NO_WORKSPACES = 'No workspaces';
export const USER_NO_WORKSPACES_MESSAGE = 'User is not a member of any workspaces yet';
export const FAILED_TO_ADD_WORKSPACES =
    'Failed to add user permissions to some workspaces, please try again later.';
export const FAILED_TO_LOAD_WORKSPACES =
    'Failed to load workspace list, you will be unable to add user permissions to workspaces, please try again later.';
export const ASSIGN_WORKSPACES = 'ASSIGN WORKSPACES';
export const ENTER_WORKSPACE = 'Enter a Workspace';
export const NO_WORKSPACES_FOUND = 'No Workspaces Found';
export const REMOVE_WORKSPACE_MESSAGE =
    'Failed to remove user from workspace, please try again later';

// Invite User Page
export const MANAGE_USERS = 'Manage Users';

// File uploads
export const ERROR_UPLOADING_FILE = 'Failed to upload file, please try again';

// Workspace Contents Page
export const GEOSCIENCE_OBJECTS = 'Geoscience Objects';

// Sidebar headers
export const FILE_PROPERTIES = 'File Properties';
export const PROPERTIES = 'Properties';
export const METADATA = 'Metadata';
export const FILE_HISTORY = 'File History';
export const VERSION_HISTORY = 'Version History';
export const WEBVIZ_BUTTON = 'View object';
export const WEBVIZ_BUTTON_DISCOVERY = 'Open in 3D Viewer';
export const FILTER_PROPERTIES = 'Filter Properties';

// Object & File Properties in Sidebar
export const CREATED_ON = 'Created On';
export const OBJECT_PATH = 'Object Path';
export const TYPE = 'Type';
export const ID = 'ID';
export const DATA_TYPE = 'Data Type';
export const FILE_SIZE = 'File Size';
export const FILE_VERSION = 'Version';
export const LAST_MODIFIED = 'Last Modified';
export const MODIFIED_BY = 'Modified By';
export const CREATED_BY = 'Created By';
export const VERSION_ID = 'Version ID';
export const VERSION_WITH_ID = 'Version {id}';
export const OBJECT_ID = 'Object ID';
export const UPLOAD_NEW_VERSION = 'Upload New Version';
export const NO_SELECTED_OBJECT = 'Select an object to see more details';

// Folder filter properties in Sidebar
export const FILTER_NAME = 'Filter Name';
export const FILTER_DESCRIPTION = 'Filter Description';

// Object Metadata in sidebar
export const SOURCE = 'Source';
export const TAGS = 'Tags';

// Affinity Dropdown
export const VIEW = 'View by: ';

// Empty folder row
export const EMPTY_FOLDER_MESSAGE = 'This folder is empty';
export const OBJECT_NOT_FOUND_TITLE = 'Geoscience object not found';
export const OBJECT_NOT_FOUND_MESSAGE = 'Object has been removed';

// Visualization page
export const ID_PARAM = 'id';
export const PLOT = 'Plot';
export const ROOT_TREE_ID = 'ROOT_TREE_ID';

// Workspaces -> Files page
export const FILES = 'Files';
export const SEARCH_PLACEHOLDER = 'Search Files';
export const DELETE_FILE_CONFIRM = 'Yes, delete file';
export const DELETE_FILE_CANCEL = 'No, keep file';
export const DELETE_FILE_DIALOG_TITLE = 'Delete File?';
export const DELETE_FILE_DIALOG_CONTENT =
    'Deleting a file will permanently remove it from your workspace.';
export const DELETE_FILE_FAILED = 'Failed to delete file, please try again later.';
export const DOWNLOAD_FILE_FAILED = 'Failed to download file, please try again later.';
export const GET_FILE_FAILED = 'File not found, please try again.';
export const GET_FILE_FAILED_GONE = 'Sorry, this file has been deleted.';
export const UPLOAD_FILES = 'Upload Files';
export const INSUFFICIENT_WORKSPACE_PERMISSION =
    'You do not have permission to modify this workspace';
export const FILES_CONTENT_NO_CONTENT = 'No Files';
export const FILES_CONTENT_NO_CONTENT_DESC =
    'Publish some files, or request to be added to the workspace';
export const NO_SELECTED_FILE = 'Select a file to see more details';

// Files page Table Headers
export const FILES_HEADER_NAME = 'Name';
export const FILES_HEADER_DATA_TYPE = 'Data Type';
export const FILES_HEADER_FILE_SIZE = 'File Size';
export const FILES_HEADER_LAST_MODIFIED = 'Last Modified';
export const FILES_HEADER_MODIFIED_BY = 'Modified By';

// Objects page Table Headers
export const OBJECT_HEADER_NAME = 'Name';
export const OBJECT_HEADER_DATA_TYPE = 'Data Type';
export const OBJECT_HEADER_LAST_MODIFIED = 'Last Modified';
export const OBJECT_HEADER_MODIFIED_BY = 'Modified By';

// Recycled Objects page Table Headers
export const OBJECT_HEADER_WORKSPACE = 'Workspace';

// Objects page Filter
export const FILTER_OBJECTS = 'Filter Objects';
export const FILTERS = 'Filters';
export const FILTER_BY_DATA_TYPE = 'BY DATA TYPE';
export const FILTER_BY_SOURCE = 'BY SOURCE';
export const FILTER_BY_UPDATED_ON = 'LAST MODIFIED';
export const FILTER_BY_UPDATED_BY = 'MODIFIED BY';
export const SEARCH_FOR_DATATYPES = 'Search for Data types';
export const UPDATED_ON_PLACEHOLDER = 'dd/mm/yyyy - dd/mm/yyyy';
export const SEARCH_FOR_SOURCES = 'Search for Sources';
export const SEARCH_FOR_USERS = 'Search for Users';
export const CLEAR_ALL = 'Clear Filters';
export const APPLY_FILTERS = 'Apply Filters';
export const UNKNOWN_USERNAME = 'Unknown Username';
export const FILTER_NO_RESULTS = 'No Results';

// Objects page Search
export const ENTER_OBJECT_NAME = 'Search Objects';

// Objects page actions
export const RECYCLE_OBJECT_ACTION = 'Recycle';

// Objects page dialog
export const RECYCLE_DIALOG_TITLE = 'Recycle Object?';
export const RECYCLE_DIALOG_CONFIRM = 'Recycle';
export const RECYCLE_DIALOG_CANCEL = 'Cancel';
export const RECYCLE_DIALOG_CONTENT = 'You are about to recycle';
export const RECYCLE_DIALOG_WARNING =
    'This object will no longer appear in your list but can be restored from the recycle bin.';
export const RECYCLE_TOAST_SUCCESS = 'Object recycled successfully';
export const RECYCLE_TOAST_FAILURE = 'Failed to recycle object';
export const UNDO = 'Undo';
export const VIEW_RECYCLED_OBJECTS = 'View Recycled objects';

export const RESTORE_DIALOG_TITLE = 'Restore Object?';
export const RESTORE_DIALOG_CONFIRM = 'Restore';
export const RESTORE_DIALOG_CANCEL = 'Cancel';
export const RESTORE_DIALOG_CONTENT = 'You are about to restore';
export const RESTORE_DIALOG_WARNING = 'This object will appear in the workspace';
export const RESTORE_TOAST_FAILURE = 'Failed to restore object';
export const RESTORE_TOAST_SUCCESS = 'Object restored successfully';
export const VIEW_RESTORED_OBJECT = 'View Restored object';

// Upload container
export const UPLOADING_FILES = 'Uploading Files';
export const UPLOAD_MORE_FILES = 'Upload More Files';

// Search page
export const SEARCH_TITLE = 'Search';
export const NO_ACCESS_DESCRIPTION =
    'This object is in a workspace that you do not have access to. Please request access from the workspace owner.';
export const METADATA_FIELD_TYPE = 'Type';
export const METADATA_FIELD_CREATED_BY = 'Created By';
export const METADATA_FIELD_CREATED_ON = 'Created On';
export const METADATA_FIELD_WORKSPACE = 'Workspace';
export const METADATA_DESCRIPTION_TITLE = 'Description';
export const METADATA_DETAILS_TITLE = 'Details';
export const DRAW_LAYER = 'Draw Layer';
export const CLEAR_DRAWN_LAYERS = 'Clear Drawn Layers';

// Search custom tooltip
export const VIEW_LAYERS = 'View Layers';
export const GLOBAL_MAP = 'Global map';
export const DRAW_LAYER_TOOLTIP = 'Draw your preferred data boundary';

// Recycle Bin Page
export const RECYCLE_BIN_TITLE = 'Recycle Bin';
export const RECYCLE_BIN_NO_CONTENT = 'Nothing in the recycle bin yet';
export const RECYCLE_BIN_NO_CONTENT_DESC =
    'Objects that have been recycled will appear here, you will be able to restore them.';

// Recycled Objects page actions
export const RESTORE_OBJECT_ACTION = 'Restore';
