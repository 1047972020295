import {
    OrgListedObject,
    useGetObjectByIdQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { MapEventBoundary } from '@local/map-viewer/dist/layers/MapEventBoundary';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { CloseIcon, OpenIcon } from '@local/web-design-system/dist/icons';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as L from 'leaflet';
import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { useMap } from 'react-leaflet';
import { useParams } from 'react-router-dom';

import { FLY_TO_BOUNDS_DURATION, FLY_TO_BOUNDS_PADDING } from 'src/constants';
import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import {
    METADATA_DESCRIPTION_TITLE,
    METADATA_FIELD_CREATED_BY,
    METADATA_FIELD_CREATED_ON,
    METADATA_FIELD_TYPE,
    METADATA_FIELD_WORKSPACE,
    WEBVIZ_BUTTON_DISCOVERY,
    NO_ACCESS_DESCRIPTION,
} from 'src/strings';
import { WEBVIZ_VIEWER } from 'src/urls';
import { isSchemaViewable } from 'src/utils/extractSchema';

import { OverflowTooltip } from '../../../components/overflowTooltip/OverflowTooltip';
import { formatObjectName, formatObjectSchema } from '../../../utils/objectUtils';
import { useStyles, useToolTipStyles } from './MetadataPanel.styles';

interface MetadataFieldProps {
    title: string;
    value: string | ReactNode;
}

function MetadataField({ title, value }: MetadataFieldProps) {
    const { classes } = useStyles();
    return (
        <Grid item container direction="row" wrap="nowrap">
            <Grid item xs={4} flexShrink={0}>
                <Typography className={classes.metadataFieldText}>{title}:</Typography>
            </Grid>
            <Grid item flexGrow={1} wrap="nowrap" overflow="hidden">
                <Typography className={classes.metadataFieldText} noWrap>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

function getRelativeEvoViewerPath(activeObject: OrgListedObject) {
    const currentHub = getHubForCurrentOrg();
    return `workspaces/${currentHub.code}/${activeObject.workspace_id}/${WEBVIZ_VIEWER}?id=${activeObject.object_id}`;
}

export function MetadataPanel() {
    const map = useMap();
    const { activeObject, setActiveObject } = useDiscoverContext();
    const { classes } = useStyles();
    const { classes: toolTipClasses } = useToolTipStyles();
    const params = useParams();
    const featureFlags = useFlags();
    const { evouiEnableGooseRendering } = featureFlags;

    const { data: gooseData, isFetching } = useGetObjectByIdQuery(
        {
            objectId: activeObject?.object_id ?? '',
            orgId: getOrgUuidFromParams(params),
            workspaceId: activeObject?.workspace_id ?? '',
            includeVersions: false,
        },
        { skip: !activeObject },
    );

    if (!activeObject) {
        return null;
    }

    const zoomToExtents = () => {
        if (!activeObject?.geojson_bounding_box) {
            return;
        }

        // GOOSE API returns lng,lat, but Leaflet requires lat,lng
        const bboxLatLong: L.LatLngTuple[] = activeObject.geojson_bounding_box.coordinates[0].map(
            (lngLat) => [lngLat[1], lngLat[0]],
        );
        const latLongBounds = new L.LatLngBounds(bboxLatLong);
        map.flyToBounds(latLongBounds, {
            duration: FLY_TO_BOUNDS_DURATION,
            padding: [FLY_TO_BOUNDS_PADDING, FLY_TO_BOUNDS_PADDING],
        });
    };

    const objectDescription = activeObject.workspace_access
        ? gooseData?.object?.description ?? 'No description available'
        : NO_ACCESS_DESCRIPTION;

    return (
        <MapEventBoundary stopClickPropagation stopScrollPropagation>
            <Grid
                container
                className={classes.base}
                direction="column"
                automation-id="object-metadata-panel"
            >
                <Grid item container direction="row" wrap="nowrap" alignItems="center" gap={1}>
                    <Grid item overflow="hidden" flexGrow={1}>
                        <OverflowTooltip
                            arrow
                            placement="top-start"
                            classes={toolTipClasses}
                            title={formatObjectName(activeObject.name)}
                        >
                            <Typography className={classes.objectNameText} noWrap>
                                {formatObjectName(activeObject.name)}
                            </Typography>
                        </OverflowTooltip>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="small"
                            classes={{ root: classes.closeButton }}
                            onClick={() => setActiveObject('')}
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container className={classes.detailsBase} gap={2} direction="column">
                    <Grid item container direction="column" gap={2}>
                        <Grid
                            item
                            container
                            className={classes.descriptionContainer}
                            direction="column"
                            flexWrap="nowrap"
                        >
                            <Grid item>
                                <Typography className={classes.descriptionTitleText}>
                                    {METADATA_DESCRIPTION_TITLE}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.descriptionTextContainer}>
                                <Typography
                                    variant="body1"
                                    className={classes.descriptionText}
                                    automation-id="object-metadata-panel-description"
                                >
                                    {activeObject.workspace_access && isFetching ? (
                                        <div>
                                            <Skeleton
                                                variant="text"
                                                width="100%"
                                                className={classes.descriptionSkeleton}
                                            />
                                            <Skeleton
                                                variant="text"
                                                width="60%"
                                                className={classes.descriptionSkeleton}
                                            />
                                        </div>
                                    ) : (
                                        objectDescription
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container direction="column">
                        <MetadataField
                            title={METADATA_FIELD_TYPE}
                            value={formatObjectSchema(activeObject.schema)}
                        />
                        <MetadataField
                            title={METADATA_FIELD_CREATED_BY}
                            value={activeObject.created_by?.name}
                        />
                        <MetadataField
                            title={METADATA_FIELD_CREATED_ON}
                            value={
                                <FormattedDate
                                    value={activeObject.created_at}
                                    month="short"
                                    year="numeric"
                                    day="2-digit"
                                />
                            }
                        />
                        {activeObject.workspace_name && (
                            <MetadataField
                                title={METADATA_FIELD_WORKSPACE}
                                value={activeObject.workspace_name}
                            />
                        )}
                    </Grid>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        classes={{ root: classes.zoomButton }}
                        onClick={zoomToExtents}
                    >
                        <Typography className={classes.zoomButtonText}>Zoom to extents</Typography>
                    </Button>
                </Grid>
                {evouiEnableGooseRendering &&
                    activeObject.workspace_access &&
                    isSchemaViewable(gooseData?.object.schema ?? '', featureFlags) && (
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            classes={{ root: classes.webvizButton }}
                            onClick={() =>
                                window.open(getRelativeEvoViewerPath(activeObject), '_blank')
                            }
                            automation-id="object-metadata-panel-evo-viewer-button"
                        >
                            <OpenIcon className={classes.evoViewerButtonIcon} />
                            <Typography className={classes.evoViewerButtonText}>
                                {WEBVIZ_BUTTON_DISCOVERY}
                            </Typography>
                        </Button>
                    )}
            </Grid>
        </MapEventBoundary>
    );
}
