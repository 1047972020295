import {
    useLazyListObjectsQuery,
    ListedObject,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import {
    XyzContext,
    XyzInstanceContextValue,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { isSchemaViewable } from 'src/utils/extractSchema';

import { useObjectFilterParams } from '../../hooks/useObjectFilterParams';
import { useObjectSearchParams } from '../../hooks/useObjectSearchParams';
import { NETWORK_ERROR_DESCR, NETWORK_ERROR_TITLE } from '../../strings';
import {
    GooseList,
    GooseListContext,
    GooseListInitial,
} from '../context/gooseContext/gooseContext';
import { ObjectsPanel } from '../ObjectsPanel/ObjectsPanel';
import { SkeletonObjectsPanelContents } from '../ObjectsPanel/ProjectTree/ProjectTreePanel';
import { Plot } from '../Plot/Plot';

const LOAD_SIZE = 20;

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    const [gooseObjectsContextValue, setGooseObjectsContextValue] =
        useState<GooseList>(GooseListInitial);

    const params = useParams();
    const { objectName } = useObjectSearchParams();
    const { filters } = useObjectFilterParams();
    const [getObjectByIdTrigger, { isError, isSuccess, isFetching }] = useLazyListObjectsQuery();
    const flags = useFlags();

    useEffect(() => {
        async function extraGooseData() {
            let gooseData;
            try {
                gooseData = await getObjectByIdTrigger(
                    {
                        orgId: getOrgUuidFromParams(params),
                        workspaceId: getSelectedWorkspaceFromParams(params),
                        ...filters,
                        objectName,
                        offset: 0,
                        limit: LOAD_SIZE,
                    },
                    true,
                ).unwrap();
            } catch (error) {
                trackError('Error loading goose objects for visualization', JSON.stringify(error));
                return;
            }

            setGooseObjectsContextValue({
                data: gooseData.objects.filter((object) => isSchemaViewable(object.schema, flags)),
            });

            let numberOfRequests = Math.floor((gooseData.total ?? 0) / LOAD_SIZE);
            const promiseList = [];
            while (numberOfRequests > 0) {
                const newPromise = getObjectByIdTrigger(
                    {
                        orgId: getOrgUuidFromParams(params),
                        workspaceId: getSelectedWorkspaceFromParams(params),
                        ...filters,
                        objectName,
                        offset: numberOfRequests * LOAD_SIZE,
                        limit: LOAD_SIZE,
                    },
                    true,
                ).unwrap();
                promiseList.push(newPromise);
                numberOfRequests -= 1;
            }

            const responseData = await Promise.allSettled(promiseList);

            let objectData: ListedObject[] = gooseData.objects;
            responseData.forEach((response) => {
                if (response.status !== 'fulfilled') {
                    trackError('Error loading goose objects for visualization');
                    return;
                }
                objectData = [...objectData, ...response.value.objects];
            });
            setGooseObjectsContextValue({
                data: objectData.filter((object) => isSchemaViewable(object.schema, flags)),
            });
        }
        extraGooseData();
    }, []);

    const projectTree = (
        <Grid item xs zIndex={1}>
            {xyzInstanceInitialized && isSuccess && <ObjectsPanel />}
            {xyzInstanceInitialized && isFetching && <SkeletonObjectsPanelContents />}
        </Grid>
    );

    const plot = (
        <Plot
            initialized={xyzInstanceInitialized}
            onInitialized={(xyzInstance: XyzInstance) =>
                setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
            }
        />
    );

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            <GooseListContext.Provider value={gooseObjectsContextValue}>
                <SplitLayout leftPanelComponent={projectTree} rightPanelComponent={plot} />
            </GooseListContext.Provider>
        </XyzContext.Provider>
    );
}
