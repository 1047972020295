import type { AttributeProps } from '@local/webviz/dist/components/Properties/AttributesControl';

import { getGooseObjectById } from 'src/store/goose/selectors';
import { useAppSelector } from 'src/store/store';
import { getTreeItemById } from 'src/store/visualization/selectors';
import { Schemas } from 'src/visualization/constants';
import { ObjectAttributeType } from 'src/visualization/DockableDialogs/DockableDialogs.types';
import type { DownholeCollectionType } from 'src/visualization/types';

export function useSelectedObjectsAttributes(selectedObjectIds: string[]) {
    let attributes: AttributeProps = {};
    const firstSelectedObject = useAppSelector(getGooseObjectById(selectedObjectIds[0]));
    const firstSelectedTreeItem = useAppSelector(getTreeItemById(selectedObjectIds[0]));
    const parentObject = useAppSelector(getGooseObjectById(firstSelectedTreeItem?.parentId ?? ''));
    const parentTreeItem = useAppSelector(getTreeItemById(firstSelectedTreeItem?.parentId ?? ''));
    switch (parentTreeItem?.schema) {
        case Schemas.DownholeCollectionSchema: {
            if (!parentObject?.object) {
                attributes = {};
                break;
            }
            const { collections } = parentObject.object as DownholeCollectionType;
            const selectedCollection = collections.find(
                (collection) => collection.name === firstSelectedTreeItem?.name,
            );
            attributes = selectedCollection?.from_to?.attributes?.reduce(reduceAttribute, {});

            break;
        }
        default: {
            attributes = firstSelectedObject?.object.attributes?.reduce(reduceAttribute, {});
        }
    }

    return { firstSelectedTreeItem, attributes };
}

function reduceAttribute(accumulator: ObjectAttributeType, { name, values }: ObjectAttributeType) {
    return {
        ...accumulator,
        [name]: { propertyId: `attribute_${values.data}` },
    };
}
