import { useBaseXyz } from '@local/webviz/dist/context';
import { handleMultiSelect } from '@local/webviz/dist/utilities';
import { useState } from 'react';

import { store, useAppDispatch, useAppSelector } from 'src/store/store';
import {
    lastSelectedScenePanel,
    loadedObjectsMap,
    selectionListScenePanel,
    shiftSelectionScenePanel,
} from 'src/store/visualization/selectors';
import {
    multiSelectObjectScenePanel,
    removeFromLoadedObjects,
    selectObjectScenePanel,
    unselectObjectScenePanel,
} from 'src/store/visualization/visualizationSlice';
import { SelectObject } from 'src/store/visualization/visualizationSlice.types';

export function useScenePanel(objectId: string, viewId: string) {
    const dispatch = useAppDispatch();
    const { entityExists, useXyzListener, getZoomToViewTool, disposeEntity } = useBaseXyz();
    const isRendered = entityExists(viewId);
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    const onClick = (event: React.MouseEvent) => {
        if (!isRendered) {
            return;
        }
        const lastSelectedId = lastSelectedScenePanel(store.getState());
        const selected = selectionListScenePanel(store.getState()).includes(objectId);
        const shiftSelection = shiftSelectionScenePanel(store.getState());

        if (lastSelectedId && event.shiftKey) {
            const loadedObjects = loadedObjectsMap(store.getState());
            const listItems = Object.keys(loadedObjects);
            const lastSelectedIndex = listItems.indexOf(lastSelectedId);
            const clickedIndex = listItems.indexOf(objectId);

            if (lastSelectedIndex === -1 || clickedIndex === -1) {
                dispatch(selectObjectScenePanel({ objectId } as SelectObject));
                return;
            }

            const [start, end] =
                lastSelectedIndex < clickedIndex
                    ? [lastSelectedIndex, clickedIndex]
                    : [clickedIndex, lastSelectedIndex];

            shiftSelection.forEach((unselectObjectId) => {
                dispatch(
                    unselectObjectScenePanel({
                        objectId: unselectObjectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            });

            for (let index = start; index <= end; index += 1) {
                dispatch(
                    multiSelectObjectScenePanel({
                        objectId: listItems[index],
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            }
            return;
        }
        handleMultiSelect({
            event,
            selected,
            onMultiSelect: () => {
                dispatch(
                    multiSelectObjectScenePanel({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
            onUnselect: () =>
                dispatch(
                    unselectObjectScenePanel({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                ),
            onSelect: () => {
                dispatch(
                    selectObjectScenePanel({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
        });
    };
    const onDoubleClick = () => {
        const loadedObjects = loadedObjectsMap(store.getState());
        Object.keys(loadedObjects).forEach((selectObjectId) => {
            dispatch(multiSelectObjectScenePanel({ objectId: selectObjectId } as SelectObject));
        });
    };

    const onRemove = () => {
        if (hasMultipleObjectSelected) {
            const isObjectSelected = selectedObjectIds.includes(objectId);
            if (isObjectSelected) {
                const viewsToRemove = views.filter((view) =>
                    selectedObjectIds.some((selectedObjectId) => view.includes(selectedObjectId)),
                );
                viewsToRemove.forEach((view) => {
                    disposeEntity(view);
                });
                selectedObjectIds.forEach((selectedObjectId) => {
                    removeObjectFromStore(selectedObjectId);
                });
                return;
            }
        }
        removeObjectFromStore(objectId);
        disposeEntity(viewId);
    };
    const removeObjectFromStore = (objectIdToRemove: string) => {
        dispatch(removeFromLoadedObjects(objectIdToRemove));
        dispatch(unselectObjectScenePanel({ objectId: objectIdToRemove }));
    };
    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (isRendered && event.key === 'Delete') {
            onRemove();
        }
    };
    const onZoomToView = () => {
        getZoomToViewTool().zoomToView(viewId);
    };

    const [isObjectVisible, setIsObjectVisible] = useState(false);
    useXyzListener(viewId, 'visible', setIsObjectVisible);
    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const hasMultipleObjectSelected = selectedObjectIds.length > 1;
    const isZoomToViewActive = isObjectVisible && !hasMultipleObjectSelected;

    return {
        onClick,
        onDoubleClick,
        onKeyUp,
        onRemove,
        onZoomToView,
        isZoomToViewActive,
    };
}
