import type { LoadedColormap } from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { SelectorTypeLoadedColormaps } from './types';

const dataKey = 'data';

const colormapState = (state: RootState) => state.colormap;

export const getOrgColormaps: SelectorTypeLoadedColormaps = createSelector(
    colormapState,
    (stateRoot) => {
        const colormaps: LoadedColormap[] = [];
        Object.values(stateRoot.queries).forEach((queryValue: any) => {
            const data = queryValue[dataKey];
            if (data) {
                colormaps.push(...data);
            }
        });
        return colormaps;
    },
);
