import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { ResetZoomButton } from '@local/map-viewer/dist/controls/ResetZoomButton';
import { ZoomInButton } from '@local/map-viewer/dist/controls/ZoomInButton';
import { ZoomOutButton } from '@local/map-viewer/dist/controls/ZoomOutButton';
import { LeafletControlContainer } from '@local/map-viewer/dist/layers/LeafletControlContainer';
import { MapEventBoundary } from '@local/map-viewer/dist/layers/MapEventBoundary';
import { MapViewer } from '@local/map-viewer/dist/MapViewer';
import { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { AttributionControl } from 'react-leaflet';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { SearchInteractionProvider } from 'src/contexts/SearchInteractionContext';
import { searchBreadcrumb } from 'src/utils/breadcrumbs';

import { GLOBAL_MAP, SEARCH_TITLE } from '../../strings';
import { ClusteredObjectMarkers } from './map/clustering';
import { ClearBoundingBoxButton } from './map/controls/ClearBoundingBoxButton';
import { DrawBoundingBoxButton } from './map/controls/DrawBoundingBoxButton';
import { MapControlTooltip } from './map/controls/MapControlsTooltip';
import { MapLayerControl } from './map/controls/MapLayerControl';
import { DisplayCoordinates } from './map/displayCoordinates/DisplayCoordinates';
import { ScaleBar } from './map/scaleBar/ScaleBar';
import { MetadataPanel } from './metadataPanel/MetadataPanel';
import { ResultsPanel } from './resultsPanel/ResultsPanel';
import { useStyles } from './SearchPage.styles';

export function SearchPage() {
    const { classes } = useStyles();
    const [bounds, setBounds] = useState<BoundingBoxCoords | null>(null);
    const [boundingBox, setBoundingBox] = useState<BoundingBoxCoords | null>(null);
    const { setSearchBounds, setSearchQuery } = useDiscoverContext();

    // We provide either a specified bounding box, or the map's viewport (bounds) as a query filter
    useEffect(() => {
        setSearchBounds(boundingBox ?? bounds);
    }, [boundingBox, bounds]);

    setDocumentTitle('Search');

    const segments: BreadcrumbSegment[] = [searchBreadcrumb];

    return (
        <SearchInteractionProvider>
            <Grid container>
                <Breadcrumb segments={segments} medium />
                <TitleRow title={SEARCH_TITLE} />
                <MapViewer
                    addMapLayers={false}
                    addZoomControls={false}
                    setQuery={setSearchQuery}
                    onBoundsChange={setBounds}
                    attributionControl={false}
                    displayCoordinates={false}
                >
                    <LeafletControlContainer position="topleft" interactive={false}>
                        <Grid
                            gap={2}
                            container
                            wrap="nowrap"
                            direction="row"
                            className={classes.searchContainer}
                        >
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <ResultsPanel />
                            </MapEventBoundary>
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <DrawBoundingBoxButton onChange={setBoundingBox} />
                            </MapEventBoundary>
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <ClearBoundingBoxButton onClear={() => setBoundingBox(null)} />
                            </MapEventBoundary>
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="topright" interactive>
                        <Grid
                            container
                            gap={2}
                            direction="column"
                            className={classes.controlContainer}
                        >
                            <ZoomInButton variant="dark" />
                            <ZoomOutButton variant="dark" />
                            <MapControlTooltip title={GLOBAL_MAP} placement="left">
                                <span>
                                    <ResetZoomButton variant="dark" />
                                </span>
                            </MapControlTooltip>
                            <MapLayerControl />
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="bottomright" interactive={false}>
                        <Grid
                            container
                            gap={2}
                            direction="column"
                            className={classes.metadataContainer}
                        >
                            <MetadataPanel />
                            <ScaleBar barWidth={140} />
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="bottom" interactive={false} fullWidth>
                        <Grid container justifyContent="center" className={classes.bottomContainer}>
                            <DisplayCoordinates />
                        </Grid>
                    </LeafletControlContainer>
                    <ClusteredObjectMarkers />
                    <AttributionControl position="bottomleft" prefix="Leaflet" />
                </MapViewer>
            </Grid>
        </SearchInteractionProvider>
    );
}
