import { Order } from '@local/web-design-system/dist/components/GenericListing/types';

import { usePersistedState } from './usePersistedState';

/**
 * Provides a custom hook for the object list sort parameters.
 * Sort parameters are stored in the url as query parameters.
 */
export function useObjectSortParams() {
    const [order, setOrder] = usePersistedState('order');
    const [key, setKey] = usePersistedState('key');

    const updateObjectSortParams = (params: { key: string; order: string }) => {
        setOrder(params.order);
        setKey(params.key);
    };

    const apiKeys: Record<string, string> = {
        name: 'object_name',
        created_by: 'author',
        created_at: 'created_at',
        schema: 'object.schema',
    };

    let orderBy;
    if (key) {
        if (order && Object.values(Order).includes(order as Order) && apiKeys[key]) {
            orderBy = `${order}:${apiKeys[key]}`;
        } else {
            orderBy = apiKeys[key];
        }
    }

    return { updateObjectSortParams, orderBy, order, key };
}
