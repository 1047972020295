import type { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';

import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { VERSION_HISTORY, VERSION_WITH_ID } from 'src/strings';

import { Icon } from './Icon';
import { ObjectActions } from './ObjectActions';

interface Props {
    data?: GetObjectResponse;
}

export const VersionHistory = ({ data }: Props) => {
    const { classes } = useStyles();

    if (!data?.versions) {
        // We've historically shown this header regardless of whether there are versions
        return <Header text={VERSION_HISTORY} />;
    }

    // typescript gets mad if you try to mutate in place, so create a new list and reverse that
    const reversed = [...data.versions].reverse();

    return (
        <div className={classes.versionHistoryContainer}>
            <Header text={VERSION_HISTORY} />
            <div className={classes.versionHistoryList}>
                {reversed.map((version, index) => (
                    <div className={classes.version} key={version.version_id}>
                        <Grid direction="column" container>
                            <div className={classes.versionHistory}>
                                <Icon schema={data.object.schema} />
                                <div className={classes.versionHistoryItem}>
                                    <Typography
                                        className={classes.versionHistoryTitle}
                                        automation-id="version-history-object-name"
                                    >
                                        {VERSION_WITH_ID.replace(
                                            '{id}',
                                            String(reversed.length - index),
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        className={classes.versionHistoryValue}
                                        automation-id={`version-id-v${reversed.length - index}`}
                                    >
                                        ID: {version.version_id}
                                    </Typography>
                                    <span className={classes.versionHistoryValue}>
                                        <PropertyDate
                                            timestamp={version.created_at}
                                            automationId={`version-created-on-v${reversed.length - index}`}
                                        />
                                    </span>
                                    <Typography
                                        variant="body2"
                                        className={classes.versionHistoryValue}
                                        automation-id={`version-created-by-v${reversed.length - index}`}
                                    >
                                        {version.created_by?.name}
                                    </Typography>
                                </div>
                                {version && <ObjectActions version={version} />}
                            </div>
                        </Grid>
                    </div>
                ))}
            </div>
        </div>
    );
};
