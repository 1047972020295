import { GeoscienceObjectVersion } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu/EllipsisMenu';
import { MenuItemConfig } from '@local/web-design-system/dist/components/Menu/Menu.types';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import CopyIcon from '@local/web-design-system/dist/icons/Actions/CopyIcon';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';

import { COPIED_TO_CLIPBOARD, COPY } from 'src/strings';

import { useStyles } from './ObjectActions.styles';

const CopyAction = () => {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <CopyIcon />
            <Typography className={classes.actionText}>{COPY}</Typography>
        </Grid>
    );
};

interface Props {
    version: GeoscienceObjectVersion;
}

export const ObjectActions = ({ version }: Props) => {
    const { addMessage } = useMessagesContext();

    const handleCopy = () => {
        navigator.clipboard.writeText(version.version_id);
        addMessage({
            message: COPIED_TO_CLIPBOARD,
            type: NotificationType.SUCCESS,
        });
    };

    const menuOptions: MenuItemConfig[] = [
        {
            key: 'copy-object-id',
            ItemComponent: CopyAction,
            action: handleCopy,
        },
    ];

    return <EllipsisMenu menuOptions={menuOptions} />;
};
