import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { Params } from 'react-router-dom';

export const WORKSPACES_BREADCRUMB = 'Workspaces';
export const WORKSPACE_FILES_BREADCRUMB = 'Files';
export const ADMINISTRATORS_BREADCRUMB = 'Administrators';
export const DISCOVERY_BREADCRUMB = 'Discover';
export const SEARCH_BREADCRUMB = 'Search';
export const RECYCLE_BIN_BREADCRUMB = 'Recycle Bin';

export const usersBreadcrumb = { name: ADMINISTRATORS_BREADCRUMB, path: '/administrators' };
export const discoveryBreadcrumb = { name: DISCOVERY_BREADCRUMB, path: '/discover' };
export const searchBreadcrumb = { name: SEARCH_BREADCRUMB, path: '/search' };
export const recycleBinBreadcrumb = { name: RECYCLE_BIN_BREADCRUMB, path: '/recyclebin' };

export const workspaceListingWithLinkBreadcrumb = (params: Params) => {
    const orgUuid = getOrgUuidFromParams(params);
    return [
        {
            name: WORKSPACES_BREADCRUMB,
            path: `/${orgUuid}/workspaces`,
        },
    ];
};
