import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PlusIcon } from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { RefreshIcon } from '@local/web-design-system/dist/icons/Actions/RefreshIcon';
import { ErrorIcon } from '@local/web-design-system/dist/icons/Alerts/ErrorIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    isSelectedInProjectTree,
    isSelectedInScenePanel,
    selectionListProjectTree,
} from 'src/store/visualization/selectors';
import { clearSelectedObjectsProjectTree } from 'src/store/visualization/visualizationSlice';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { ID_PARAM } from 'src/strings';
import { useDrag } from 'src/visualization/context/hooks/useDragAndDrop';
import { useObjectLoader } from 'src/visualization/context/hooks/useObjectLoader/useObjectLoader';
import { useObjectManager } from 'src/visualization/context/hooks/useObjectManager/useObjectManager';

import { APPROXIMATE_MAX_LOAD_TIME, ERROR_MESSAGE } from './ObjectListItemControl.constants';
import { useStyles } from './ObjectListItemControl.styles';

export function ObjectListItemControl(treeItem: TreeStructure) {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID_PARAM) ?? '';
    const { treeId: objectId, name, schema, parentId } = treeItem;
    const {
        loadObject,
        loadSelectedObjects,
        reloadObject,
        isError: isXyzError,
        isLoading: isXyzLoading,
        isObjectLoaded,
    } = useObjectManager(objectId);
    const { isLoading: isNetworkLoading, isError: isNetworkError } = useObjectLoader(
        treeItem,
        false,
    );
    useEffect(() => {
        if ([objectId, parentId].includes(objectIdQueryParam)) {
            loadObject();
        }
    }, []);

    const selectedObjectIds = useAppSelector(selectionListProjectTree);
    const handleAddObject = () => {
        if (selectedObjectIds.includes(objectId)) {
            loadSelectedObjects();
            dispatch(clearSelectedObjectsProjectTree());
        } else {
            loadObject();
        }
    };
    const onKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAddObject();
        }
    };
    const handleFailedObject = () => {
        reloadObject();
    };

    const { onClick, onDoubleClick, onDragStart } = useDrag(objectId);
    const isSelectedScenePanel = useAppSelector(isSelectedInScenePanel(objectId));
    const isSelectedProjectTree = useAppSelector(isSelectedInProjectTree(objectId));
    const isObjectOnPlot = isObjectLoaded(objectId);
    const Icon = SchemaIcon(schema);

    const isLoading = isXyzLoading || isNetworkLoading;
    const isError = isXyzError || isNetworkError;
    return (
        <Grid item container className={classes.root}>
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isLoading,
                    [classes.backgroundClear]: isObjectOnPlot && !isError,
                    [classes.backgroundSelected]: isSelectedScenePanel,
                    [classes.backgroundSelectedForLoading]:
                        isSelectedProjectTree && (!isObjectOnPlot || isError),
                })}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.cursor]: !isObjectOnPlot,
                    })}
                    alignItems="center"
                    draggable
                    onClick={onClick}
                    onDragStart={onDragStart}
                    onDoubleClick={onDoubleClick}
                    onKeyUp={onKeyUp}
                    tabIndex={isObjectOnPlot ? undefined : 0}
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon fontSize="small" />
                        </Grid>
                        <Grid item xs>
                            <Tooltip title={fileNameExtensionRemover(name)}>
                                <Typography
                                    variant="body2"
                                    color={isError ? 'error' : 'inherit'}
                                    className={classes.name}
                                >
                                    {fileNameExtensionRemover(name)}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {isLoading && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={isLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!isError && !isNetworkError && !isObjectOnPlot && (
                        <IconButton
                            onClick={handleAddObject}
                            className={classnames(classes.loadIconButton)}
                        >
                            <PlusIcon fontSize="inherit" />
                        </IconButton>
                    )}
                    {isError && (
                        <IconButton
                            onClick={handleFailedObject}
                            className={classnames(classes.loadIconButton)}
                        >
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>

            {isError && (isSelectedProjectTree || isSelectedScenePanel) && (
                <Grid container alignItems="center" zIndex={1}>
                    <Grid item className={classes.errorIcon}>
                        <ErrorIcon fontSize="small" />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="caption" color="error">
                            {ERROR_MESSAGE}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
