import { theme } from '@local/web-design-system/dist/styles';
import Tooltip from '@mui/material/Tooltip';

type Placement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;

export const MapControlTooltip = ({
    children,
    title,
    placement,
}: {
    children: React.ReactElement;
    title: string;
    placement: Placement;
}) => (
    <Tooltip
        title={title}
        placement={placement}
        arrow
        sx={{ fontSize: '30' }}
        slotProps={{
            tooltip: {
                sx: {
                    color: theme.palette.grey[700],
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: theme.spacing(0.75),
                },
            },
            arrow: {
                sx: {
                    color: '#d0d0d0',
                },
            },
        }}
    >
        {children}
    </Tooltip>
);
