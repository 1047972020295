import { useLazyGetDummyColormapCollectionQuery } from '@local/api-clients/dist/colormap/enhancedColormapClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { generateEntity } from '@local/webviz/dist/context/snapshots';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { ArrayClass, MappingClass, toSuffixUid } from '@local/webviz/dist/xyz';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useColormapLoader() {
    const [fetchColormaps] = useLazyGetDummyColormapCollectionQuery();

    const { evouiEnableColormapsApi } = useFlags();
    const { setStateFromSnapshot } = useBaseXyz();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);

    useEffect(() => {
        async function loadColormapsByOrgId() {
            let downloadedColorMaps = [];
            try {
                downloadedColorMaps = await fetchColormaps({ org: orgId }).unwrap();
            } catch (error) {
                trackError('Error loading goose object for visualization', JSON.stringify(error));
                return;
            }
            downloadedColorMaps.forEach((loadedColorMap) => {
                const {
                    id,
                    colors,
                    attribute_controls: attributeControls,
                    gradient_controls: gradientControls,
                } = loadedColorMap;
                const mappingId = toSuffixUid(id, UID_SUFFIXES.MAPPING);
                const gradientId = toSuffixUid(id, UID_SUFFIXES.GRADIENT);
                const snapshot = {
                    [mappingId]: generateEntity(MappingClass.Continuous, {
                        id: mappingId,
                        gradient: gradientId,
                        gradient_control_values: gradientControls,
                        data_control_values: attributeControls,
                        visibility: Array(gradientControls.length + 1).fill(true),
                    }),
                    [gradientId]: generateEntity(ArrayClass.Color, {
                        id: gradientId,
                        array: colors,
                    }),
                };

                setStateFromSnapshot(snapshot, {});
            });
        }
        if (evouiEnableColormapsApi) {
            loadColormapsByOrgId();
        }
    }, []);
}
