import { useLazyGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useLazyGetTilesetByObjectIdQuery } from '@local/api-clients/dist/visualization/enhancedVisualizationClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import type { MetadataState, UpdateSnapshot } from '@local/webviz/dist/types';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { toSuffixUid, ElementClass } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import isFinite from 'lodash-es/isFinite';
import merge from 'lodash-es/merge';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getOrgColormaps } from 'src/store/colormap/selectors';
import { getGooseObjectById } from 'src/store/goose/selectors';
import { useAppSelector } from 'src/store/store';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { createViewSnapshot } from 'src/visualization/context/snapshots/generateSnapshot';
import { Tileset } from 'src/visualization/types';

import { categoryDataSnapshot } from '../../snapshots/categoryDataSnapshot';

export function useObjectLoader(treeItem: TreeStructure, shouldLoadOnMount = true) {
    const { getEntityState, setStateFromSnapshot, addViewToPlotDirectly, addListener } =
        useBaseXyz();
    const loadedColorMaps = useAppSelector(getOrgColormaps);
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const [getObjectByIdTrigger, { isError: isNetworkError, isLoading: isGooseLoading }] =
        useLazyGetObjectByIdQuery();
    const [getTilesetByTrigger, { isError: isTilesetNetworkError, isLoading: isTilesetLoading }] =
        useLazyGetTilesetByObjectIdQuery();
    const parentGooseResponse = useAppSelector(getGooseObjectById(treeItem.parentId));

    useEffect(() => {
        if (!shouldLoadOnMount) return () => {};
        async function getObjectViewSnapshot() {
            if (parentGooseResponse) {
                // assumption that if a parent object exists (i.e. the object is part of a composite), then the parent and tileset are passed to create a snapshot
                // if newly supported composites are rendered differently, a switch case will be needed here
                try {
                    const tileset: Tileset = (await getTilesetByTrigger(
                        {
                            orgId,
                            workspaceId,
                            objectId: treeItem.parentId,
                        },
                        true,
                    ).unwrap()) as any;

                    return createViewSnapshot(
                        treeItem,
                        loadedColorMaps,
                        parentGooseResponse,
                        {
                            objectId: treeItem.parentId,
                            workspaceId,
                            orgId,
                        },
                        tileset,
                    );
                } catch (error: any) {
                    trackError(error);
                    return undefined;
                }
            }
            const gooseResponse = await getObjectByIdTrigger(
                {
                    objectId: treeItem.treeId,
                    orgId,
                    workspaceId,
                },
                true,
            ).unwrap();
            return createViewSnapshot(treeItem, loadedColorMaps, gooseResponse, {
                objectId: gooseResponse.object_id,
                workspaceId,
                orgId,
            });
        }

        const listeners: (() => void)[] = [];
        getObjectViewSnapshot().then(async (viewSnapshot) => {
            if (!viewSnapshot?.snapshot) return;
            const newSnapshot = viewSnapshot.snapshot;
            if ('status' in newSnapshot) {
                delete newSnapshot.status;
            }
            await setStateFromSnapshot(newSnapshot, {});
            addViewToPlotDirectly(viewSnapshot.viewId);

            Object.keys(viewSnapshot.snapshot).forEach((elementId) => {
                if (!elementId.includes(ElementClass.Tileset3D)) return;
                const removeElementMetadataListener = addListener(
                    elementId,
                    'metadata',
                    async (attributes) => {
                        if (!attributes?.attributes_metadata) return;
                        let { attributes_metadata: attributesMetadata } = attributes;

                        const downloadMetadataRequests: Promise<void>[] = [];
                        const downloadedMetadata: MetadataState = {};
                        const attributeIds = Object.keys(attributesMetadata);
                        attributeIds.forEach((attributeId) => {
                            if ('uri' in attributesMetadata[attributeId]) {
                                const { uri } = attributesMetadata[attributeId];
                                downloadMetadataRequests.push(
                                    fetch(uri)
                                        .then(async (response) => {
                                            if (!response.ok) {
                                                throw new Error(
                                                    `Failed to download attribute ${attributeId} with status code ${response.status}`,
                                                );
                                            }
                                            const metadata = await response.json();
                                            downloadedMetadata[attributeId] = metadata;
                                            delete attributesMetadata[attributeId].uri;
                                        })
                                        .catch((error) => trackError(error)),
                                );
                            }
                        });
                        await Promise.all(downloadMetadataRequests);
                        attributesMetadata = merge(attributesMetadata, downloadedMetadata);
                        const attributesSnapshot = attributeIds.reduce(
                            (accumulator: UpdateSnapshot, attributeId) => {
                                if ('metadata' in attributesMetadata[attributeId]) {
                                    const { metadata } = attributesMetadata[attributeId];
                                    if ('min' in metadata && 'max' in metadata) {
                                        const mappingId = toSuffixUid(
                                            attributeId,
                                            UID_SUFFIXES.MAPPING,
                                        );
                                        const mappingEntity = getEntityState(mappingId);
                                        if (
                                            mappingEntity &&
                                            'data_control_values' in mappingEntity &&
                                            mappingEntity.data_control_values.every((value) =>
                                                isFinite(value),
                                            )
                                        ) {
                                            return { ...accumulator };
                                        }

                                        const { min, max } = metadata;
                                        const minValue = isFinite(min) ? min : -Infinity;
                                        const maxValue = isFinite(max) ? max : +Infinity;
                                        return {
                                            ...accumulator,
                                            [mappingId]: {
                                                data_control_values: [
                                                    minValue,
                                                    minValue,
                                                    maxValue,
                                                    maxValue,
                                                ],
                                            },
                                        };
                                    }

                                    if ('lookup_table' in metadata) {
                                        const { lookup_table: lookupTable } = metadata;
                                        return {
                                            ...accumulator,
                                            ...categoryDataSnapshot(attributeId, lookupTable),
                                        };
                                    }
                                }
                                return { ...accumulator };
                            },
                            {},
                        );
                        setStateFromSnapshot(attributesSnapshot, {});
                    },
                );
                listeners.push(removeElementMetadataListener);
            });
        });
        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, [shouldLoadOnMount, loadedColorMaps.length]);

    return {
        isError: isNetworkError || isTilesetNetworkError,
        isLoading: isGooseLoading || isTilesetLoading,
    };
}
